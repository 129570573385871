import { useLocationMatch } from '@kaliber/routing'
import { useTranslate } from '/machinery/I18n'
import { CardVertical, CardVerticalReversed } from '/features/buildingBlocks/Card'
import { ImageCoverDynamicScale } from '/features/buildingBlocks/Image'
import { VideoWithContext } from '/features/buildingBlocks/Video'
import { CardWithoutImage } from '/features/pageOnly/lifeAtRituals/CardWithoutImage'
import { routeMap } from '/routeMap'
import { HeadingSm } from '/features/buildingBlocks/Heading'
import { useMediaQuery } from '@kaliber/use-media-query'

import mediaStyles from '/cssGlobal/media.css'
import cssStyleContext from '/cssGlobal/style-context.css'
import styles from './GridMasonry.css'

export function GridMasonry({ items }) {
  const { videoAsFirstItem, leftColItems, rightColItems } = useNormalizeColumns(items)

  return (
    <div className={styles.component}>
      {videoAsFirstItem &&
        <div className={styles.videoCell}>
          <Item
            key='video'
            type={videoAsFirstItem._type}
            contentItem={videoAsFirstItem}
            side='left'
          />
        </div>
      }
      <Column colItems={leftColItems} isLeft layoutClassName={styles.columnLeftLayout} />
      {Boolean(rightColItems.length) && <Column colItems={rightColItems} layoutClassName={styles.columnRightLayout} />}
    </div>
  )
}

function Column({ colItems, isLeft = undefined, layoutClassName = undefined }) {
  const side = isLeft ? 'left' : 'right'

  return (
    <div className={cx(styles.componentColumn, layoutClassName)}>
      {colItems?.map((x, i) => {
        return (
          <Item
            key={i}
            type={x.contentItem._type}
            contentItem={x.contentItem}
            presentationProps={x.presentationProps}
            {...{ side }}
          />
        )
      })}
    </div>
  )
}

function useNormalizeColumns(allItems) {
  // contentItems represent the actual content (article, img, video)
  // colItems represent objects with a contentItem and its presentationProps
  // alternately put contentItems into arrays leftColItems and rightColItems
  const [...contentItems] = allItems
  const isMobile = useMediaQuery(mediaStyles.viewportMd) === false

  const articleInColCountRef = React.useRef(0)
  const setArticleInColCount = React.useCallback(handleArticleInColCount, [])

  const videoAsFirstItem = React.useMemo(() => isVideo(contentItems[0]) ? contentItems.shift() : null, [contentItems])

  const leftColItems = React.useMemo(() => {
    const colContentItems = isMobile
      ? contentItems.filter(contentItem => !isImage(contentItem))
      : contentItems.filter((contentItem, i) => i % 2 === 0)
    const colItems = createColItems({ contentItems: colContentItems, isLeftCol: true, articleInColCount: articleInColCountRef?.current, setArticleInColCount })

    return colItems
  }, [contentItems, setArticleInColCount, isMobile])

  const rightColItems = React.useMemo(() => {
    const colContentItems = isMobile
      ? []
      : contentItems.filter((contentItem, i) => i % 2 === 1)

    const colItems = createColItems({ contentItems: colContentItems, isLeftCol: false, articleInColCount: articleInColCountRef?.current, setArticleInColCount })
    return colItems
  }, [contentItems, setArticleInColCount, isMobile])

  return {
    videoAsFirstItem,
    leftColItems,
    rightColItems
  }

  /** @param {number} count   */
  function handleArticleInColCount(count) {
    articleInColCountRef.current = count
  }
}

function createColItems({ contentItems, isLeftCol, articleInColCount, setArticleInColCount }) {
  setArticleInColCount(0)

  const colItems = []
  contentItems.forEach((contentItem, i) => {
    const presentationProps = getPresentationProps({ contentItem, i, articleInColCount, isLeftCol })
    if (isArticle(contentItem)) {
      articleInColCount++
    }

    colItems.push({ contentItem, presentationProps })
  })
  return colItems
}

function getPresentationProps({ contentItem, i, articleInColCount, isLeftCol }) {
  /*
  INFO: item logic:
  per column: by default, articles alternately show and hide their image
  when cardSettings.imageVisibility is set to 'show' or 'hide', that
  overrules the default for that card only (The other cards then still
  behave exactly the same)
  articles alternately have white and gray background
  so, since articles alternately show / hide image,
  for articles without an override for image visibility:
  - article with image has contextWhite
  - article without image has contextGray
  leftCol
   - starts with article with image
   - text is shown above image
  rightCol
   - starts with article without image
   - text is shown below image
  */

  const context = getStyleContext({ contentItem, articleInColCount, isLeftCol })
  const cardComponent = getCardComponent({ contentItem, i, articleInColCount, isLeftCol })
  const layoutClassName = cardComponent === CardWithoutImage ? styles.withoutImageLayout : null

  return {
    layoutClassName,
    context,
    cardComponent,
  }
}

function isArticle(contentItem) {
  return contentItem._type === 'article'
}

function isArticleWithImage({ contentItem, articleInColCount, isLeftCol }) {
  if (!isArticle(contentItem)) {
    return false
  }
  const { imageVisibility } =  (contentItem.cardSettings || {})
  if (imageVisibility === 'show') {
    return true
  }
  if (imageVisibility === 'hide') {
    return false
  }
  return isLeftCol
    ? articleInColCount % 2 === 0
    : articleInColCount % 2 === 1
}

function isImage(contentItem) {
  return contentItem?._type === 'imageRef'
}

function isVideo(contentItem) {
  return contentItem?._type === 'video'
}

function getStyleContext({ contentItem, articleInColCount, isLeftCol }) {
  if (!isArticle(contentItem)) {
    return cssStyleContext.contextGray
  }

  if (isLeftCol) {
    return (articleInColCount % 2 === 0
      ? cssStyleContext.contextWhite
      : cssStyleContext.contextGray
    )
  } else {
    return (articleInColCount % 2 === 1
      ? cssStyleContext.contextWhite
      : cssStyleContext.contextGray
    )
  }
}


function getCardComponent({ contentItem, i, articleInColCount, isLeftCol }) {
  if (isArticleWithImage({ contentItem, articleInColCount, isLeftCol })) {
    return isLeftCol ? CardVertical : CardVerticalReversed
  } else {
    return CardWithoutImage
  }
}

function Item({ type, contentItem, side, presentationProps = undefined }) {
  const { params: { language } } = useLocationMatch()
  const { __ } = useTranslate()

  return (
    <div
      className={cx(styles.componentItem, side === 'left' ? styles.isLeft : styles.isRight)}
      data-style-context={presentationProps?.context}
    >
      {type === 'article' && (
        <presentationProps.cardComponent
          type={contentItem.category}
          title={contentItem.cardSettings?.titleOverwrite || contentItem.title}
          label={contentItem?.cardSettings?.cardLabel || __`read-more`}
          description={contentItem?.cardSettings?.cardDescription}
          href={routeMap.app.lifeAtRituals.article({ language, slug: contentItem.slug.current })}
          dataX='link-to-article'
          image={contentItem.image}
          layoutClassName={cx(styles.cardLayout, presentationProps.layoutClassName)}
          heading={{ h: 2, headingMobile: HeadingSm, headingDesktop: HeadingSm }}
        />
      )}

      {type === 'video' && (
        <VideoWithContext
          url={contentItem.video.url}
          poster={contentItem.video.poster}
          trackingTitle={contentItem.video.title}
          title={contentItem.sequenceTitle}
          description={contentItem.videoTitle}
          layoutClassName={styles.videoLayout}
        />
      )}

      {type === 'imageRef' && (
        <ImageCoverDynamicScale
          image={contentItem.image}
          layoutClassName={styles.imageLayout}
          aspectRatio={1}
        />
      )}
    </div>
  )
}
